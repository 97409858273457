export const paddingBottomClass = "pb2 mb4"
export const studentsAndAlumniClass = "bg-mid-gray pa2 ph5-ns center mw8 mw8-ns shadow-5"
export const personSecClass = "bg-white dib ph2 pt3 pb4 ma3 ba b--black-10 br1"
export const animatedArrowClass = "dib link"
export const shiftDownAnimationClass = "shiftDownAnimation 2s infinite"
export const shiftUpAnimationClass = "shiftUpAnimation 2s infinite"
export const pb2 = "pb2"
export const f5 = "f5"
export const navyBorderSecClass = "pa2 mb2 mt3 black tl ba b--navy br1"
export const titleArrowClass = "dib navy link"
export const foldArrowClass = "w-100 pb1 center b f4 dim grow bn"
export const contentWithFoldingArrowSecClass = "ph4 pt1 mt1"
export const contentTitleClass = "pb1 b f5"
export const entryTitleSecClass = "w-100 pt1 pl2 navy tl b f4 dim bn"
